"use client";

import dynamic from "next/dynamic";
import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
import EntypoGameController from "react-entypo/lib/entypo/GameController";
import EntypoKey from "react-entypo/lib/entypo/Key";
import EntypoShoppingCart from "react-entypo/lib/entypo/ShoppingCart";
import { cx as classNames } from "class-variance-authority";

import { BlogPost, blogPosts } from "../../../../blogposts";
import { FontCabin } from "../../../components/fonts/cabin";
import { FontSegoe } from "../../../components/fonts/segoe";
import { Article } from "../../../components/article";
import { RightTriangle, LeftTriangle } from "../../../components/RightTriangle";
import { generateColorFromIndex } from "../../../utils/colors";
import { CSSProp } from "styled-components";
import { SocialLinks } from "../../../components/SocialLinks";

export function Home() {
  return (
    <>
      <FontCabin>
        <section>
          <HiImTopherWinward />
          <div className="mb-32 -mt-8 md:-mt-16">&nbsp;</div>
        </section>
        <section>
          <BodyContainer>
            <HeaderWithLine>Most Recent Articles</HeaderWithLine>
            <RecentArticlesSection />
          </BodyContainer>
        </section>
        <div className="h-8 mt-8 md:h-24">
          <LeftTriangle extraCSS={"text-gray-300"} />
        </div>
        <main className={"bg-gray-300"}>
          <WhoAmI />
        </main>
        <section>
          <ProjectSection />
        </section>
      </FontCabin>
    </>
  );
}

function ProjectHeader({ children }) {
  return (
    <div className="w-full px-4 md:px-0">
      <FontSegoe>
        <h2 className="ml-4 text-base text-black uppercase">{children}</h2>
        <hr className={"w-full"} />
      </FontSegoe>
    </div>
  );
}

const TopherWinward = dynamic(
  () => import("../../../components/HiImTopherWinward"),
  {
    loading: () => <>Topher Winward</>,
  }
);

function HiImTopherWinward() {
  return (
    <>
      <div className="flex flex-col items-center px-6">
        <div className={"flex flex-col mt-8 md:mt-32"}>
          <h1 className="sr-only">Hi, I'm Topher Winward.</h1>
          <h1
            aria-hidden
            className="flex flex-col text-6xl font-bold leading-none text-center md:flex-row"
          >
            <span className="hidden md:inline">Hi, I'm&nbsp;</span>
            <span>
              <TopherWinward />
            </span>
          </h1>
          {
            <>
              <small className="flex flex-col items-center mt-4 text-center text-gray-600 uppercase md:text-right md:justify-end md:items-end md:mr-1 md:mt-0">
                <span>
                  <small className="sr-only">
                    Joy finder and software developer
                  </small>
                  <div aria-hidden>
                    Joy finder{" "}
                    <span className="hidden sm:inline">&amp;&amp;</span>
                    <span className="inline sm:hidden">|</span> software
                    developer
                  </div>
                </span>
                <SocialLinks />
              </small>
            </>
          }
        </div>
      </div>
    </>
  );
}

function blogPostToArticle(
  blogPost: BlogPost
): {
  title: string;
  description: string;
  pubDate: Date;
  link: string;
  thumbnail: string;
} {
  return {
    title: blogPost.title,
    description: blogPost.description,
    pubDate: blogPost.date,
    link: `/writing/${blogPost.slug}`,
    thumbnail: blogPost.heroImage,
  };
}

function RecentArticlesSection() {
  const blogpostsCount = blogPosts.length;
  const article0 = blogPostToArticle(blogPosts[blogpostsCount - 1]);
  const article1 = blogPostToArticle(blogPosts[blogpostsCount - 2]);
  const article2 = blogPostToArticle(blogPosts[blogpostsCount - 3]);

  return (
    <>
      <div className="grid gap-8 mx-6 my-8 md:grid-rows-2 md:grid-cols-2">
        <article className="row-span-2">
          <Article
            borderColor={"border-red-400 hover:border-red-600"}
            article={article0}
          />
        </article>
        <article>
          <Article
            borderColor={"border-indigo-400 hover:border-indigo-600"}
            article={article1}
          />
        </article>
        <article>
          <Article
            borderColor={"border-green-400 hover:border-green-600"}
            article={article2}
          />
        </article>
      </div>
      <div className="mt-4" />
      <Link href="/writing" passHref legacyBehavior>
        <a>
          <div className="p-4 mx-6 text-xl font-semibold text-center text-white bg-pink-500 border border-pink-400 rounded-lg shadow-lg hover:bg-pink-600">
            <FontSegoe>See all writing</FontSegoe>
          </div>
        </a>
      </Link>
    </>
  );
}

function HeaderWithLine({ children }) {
  return (
    <div className="w-full px-4 md:px-0">
      <FontSegoe>
        <h2 className="ml-4 text-base font-thin text-gray-700 uppercase">
          {children}
        </h2>
        <hr className="w-full" />
      </FontSegoe>
    </div>
  );
}

function BodyContainer({ children }) {
  return (
    <div className="container px-1 mx-auto md:px-8 lg:px-32">{children}</div>
  );
}

function WhoAmI() {
  return (
    <div className="container px-8 pt-8 mx-auto text-xl lg:px-24">
      <div>
        <div className="flex flex-row items-center justify-around mb-8 space-x-2 lg:hidden">
          <div>
            <h2 className="text-5xl">Hey there!</h2>
            <div className="text-3xl font-normal font-segoe">
              <p>I'm Topher.</p>
            </div>
          </div>
          <div className="w-1/3 pr-4">
            <TopherProfileImage />
          </div>
        </div>
        <div className="flex justify-between space-x-8">
          <div className="hidden w-1/3 pt-8 lg:flex lg:justify-start lg:items-start">
            <TopherProfileImage />
          </div>
          <section className="mb-8 lg:w-full">
            <h2 className="hidden text-5xl lg:block">Hey there!</h2>
            <div className="space-y-8 font-normal font-segoe">
              <p className="hidden text-3xl lg:block">I'm Topher.</p>
              <p>
                I design and develop software that helps people be as effective
                as they can be.
                <br />I also write about topics that interest me such as{" "}
                <Link
                  href="https://topher.io/writing/five-tips-giving-great-feedback"
                  passHref
                  legacyBehavior
                >
                  <a className="text-blue-600 underline">teamwork</a>
                </Link>
                ,{" "}
                <Link
                  href="https://topher.io/writing/how-concurrent-react-changes-the-game-for-data-heavy-ui"
                  passHref
                  legacyBehavior
                >
                  <a className="text-green-600 underline">web programming</a>
                </Link>
                , and{" "}
                <Link
                  href="https://topher.io/writing/counting-crowds-hyperloglog-in-simple-terms"
                  passHref
                  legacyBehavior
                >
                  <a className="text-orange-600 underline">
                    complex computer science topics made simple
                  </a>
                </Link>
                .
              </p>
              <p>
                I've been lucky enough to work on some great projects including:
              </p>
            </div>
          </section>
        </div>
        <div className="flex-shrink-0">
          <SomeGreatProjects />
        </div>
      </div>
    </div>
  );
}

function TopherProfileImage() {
  return (
    <div className="md:w-64">
      <Image
        src={require("../../../images/topher.jpg")}
        alt="Photo of Topher Winward"
        width={768}
        height={768}
        loading="eager"
        priority
        className="bg-green-800 rounded-md card-shadow"
      />
    </div>
  );
}

function SomeGreatProjects() {
  return (
    <>
      <dl
        style={{
          display: "grid",
          gridTemplateColumns: "80px 1fr",
          justifyItems: "left",
          alignItems: "center",
          columnGap: "1rem",
          rowGap: "2rem",
        }}
        className={classNames("px-4")}
      >
        <>
          <dt>
            <EntypoKey
              aria-label="Key icon"
              style={{ verticalAlign: "middle" }}
              className="text-3xl"
            />
          </dt>
          <dd>
            Zing, an internal database with complex authorization systems for a
            UK wide children's arts, drama, and music school
          </dd>
        </>
        <>
          <dt>
            <EntypoGameController
              style={{ verticalAlign: "middle" }}
              aria-label="Game controller icon"
              className="text-3xl"
            />
          </dt>
          <dd>
            <a
              href="http://www.seaofthieves.com/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Sea Of Thieves
            </a>
            , a AAA multiplayer game in C++ with Unreal, played by millions of
            gamers
          </dd>
        </>
        <>
          <dt>
            <EntypoShoppingCart
              style={{ verticalAlign: "middle" }}
              aria-label="Shopping cart icon"
              className="text-3xl"
            />
          </dt>
          <dd>
            Building a secure, customer friendly, realtime fullstack ecommerce
            website for my event ticketing company{" "}
            <a
              href="https://matterpay.com/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              MatterPay
            </a>
          </dd>
        </>
      </dl>
    </>
  );
}

function ProjectSection() {
  return (
    <>
      <div className="h-12 pt-8 bg-gray-300 md:h-24">
        <RightTriangle extraCSS={"text-gray-500"} />
      </div>

      <div className="pt-24 bg-gray-500">
        <BodyContainer>
          <ProjectHeader>Other projects</ProjectHeader>
          <div className="mb-8" />
        </BodyContainer>
        <OtherProjects />
      </div>
      <style jsx>{`
        .slant {
          position: relative;
          z-index: 1;
        }

        .slant:after {
          background: inherit;
          content: "";
          display: block;
          height: 50%;
          left: 0;
          position: absolute;
          right: 0;
          z-index: -1;
          -webkit-backface-visibility: hidden; // for Chrome Windows
        }

        .slant:after {
          top: 0;
          transform: skewY(-1.5deg);
          transform-origin: 100% 0;
        }
      `}</style>
    </>
  );
}

function OtherProjects() {
  return (
    <>
      <div
        style={{ height: "200vh" }}
        className="grid w-full grid-flow-row-dense grid-cols-2 gap-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 sm:gap-2 xl:gap-4"
      >
        <Project
          project={{
            name: "Everwild",
            description:
              "Unique and unforgettable experiences await in a natural and magical world.",
            extraCSS: "lg:col-span-2",
            imgFull: require("../../../images/projects/everwild1.jpg"),
            contain: false,
            link: "https://www.xbox.com/en-GB/games/everwild",
            linkIsExternal: true,
            index: 0,
          }}
        />
        <Project
          project={{
            name: "MatterPay",
            description: (
              <>
                Simple event ticketing.
                <br />
                <br />
                Forget cash, cheques and paper forms. Simplify how you manage
                events, collect payments and handle customer data.
                <br />
                <br />I led the software design and implementation of Matterpay.
                The frontend is an accessible, responsive and highly performant
                React application. This connects to a heavily unit-tested
                Node-based GraphQL API, accessing a PostgreSQL database.
              </>
            ),
            extraCSS: "col-span-2 md:col-span-3",
            imgFull: require("../../../images/work/matterpay/logo.png"),
            contain: true,
            link: "https://matterpay.com",
            linkIsExternal: true,
            index: 1,
          }}
        />
        <Project
          project={{
            name: "Sea Of Thieves",
            description: (
              <>
                The essential pirate experience from Rare, packed to the seams
                with sailing and exploring, fighting and plundering, riddle
                solving and treasure hunting!
                <br />
                <br />
                My responsibilities have crossed many areas, from gameplay work
                in Unreal Engine 4 C++, lower-level engine optimizations,
                building a UI framework in Typescript, and building C# .NET
                microservices deployed to Azure.
              </>
            ),
            extraCSS: "md:col-span-2",
            imgFull: require("../../../images/work/rare/promo2.jpg"),
            contain: false,
            link: "https://seaofthieves.com",
            linkIsExternal: true,
            index: 2,
          }}
        />
        <Project
          project={{
            name: "Rare Replay",
            description:
              "Celebrate Rare's 30th Anniversary with a jaw-dropping collection of 30 iconic games, lovingly presented for Xbox One.",
            imgFull: require("../../../images/projects/rare-replay.jpg"),
            contain: true,
            link: "https://www.rare.co.uk/games/rare-replay",
            linkIsExternal: true,
            index: 3,
          }}
        />
        <Project
          project={{
            name: "Succeed",
            extraCSS: "xl:col-span-2",
            imgFull: require("../../../images/projects/succeed.jpg"),
            contain: true,
            index: 4,
          }}
        />
        <Project
          project={{
            name: "Sheffield Hubs Hub",
            description:
              "A tool built in Meteor.js to organize 1000+ engineering students into groups, providing realtime chat capabilities and student identification.",
            imgFull: require("../../../images/projects/eyh.jpg"),
            index: 5,
          }}
        />
        <Project
          project={{
            name: "How many coms would a dot com com if a dot com could .com?",
            imgFull: require("../../../images/projects/howmanycoms/promo.png"),
            contain: true,
            link: "https://howmanycomswouldadotcomcomifadotcomcould.com/",
            linkIsExternal: true,
            extraCSS: "row-span-2 xl:col-span-2",
            index: 6,
          }}
        />
        <Project
          project={{
            name: "Is there space in the IC?!",
            description:
              "My university dissertation involved applying machine learning models to data collected about space availability in various libraries, and presenting the projected information in a friendly manner.",
            extraCSS: "xl:col-span-2",
            imgFull: require("../../../images/projects/ic.jpg"),
            // contain: true,
            index: 7,
          }}
        />
        <Project
          project={{
            name: "KittenMouse",
            description:
              "A toy project that finds an image of a cat that will look at your mouse, wherever on the screen you place it.",
            extraCSS: "lg:col-span-2",
            imgFull: require("../../../images/projects/kittenmouse.jpg"),
            // contain: true,
            index: 8,
          }}
        />
        <Project
          project={{
            name: "KoTH_Kalamity",
            description:
              "A map created for Team Fortress 2 during the Mercs vs Aliens map making contest.",
            link:
              "https://steamcommunity.com/sharedfiles/filedetails/?id=1845954732",
            linkIsExternal: true,
            imgFull: require("../../../images/projects/koth-kalamity.jpg"),
            index: 9,
          }}
        />
        <Project
          project={{
            name: "Nyan Cat's Explosive Adventures Through The Interhoods",
            description:
              "A Java applet based game where you control Nyan Cat as he travels along a rainbow road, battling the evil ducks along the way. With a dynamic custom-written music system, over 3000 players battled to reach the high score on the public leaderboard.",
            extraCSS: "col-span-2 xl:row-span-2",
            imgFull: require("../../../images/projects/nyancat.jpg"),
            index: 10,
          }}
        />
        <Project
          project={{
            name: "CTF_Mexico",
            extraCSS: "md:row-span-2 lg:col-span-2",
            imgFull: require("../../../images/projects/mexico/paint.jpeg"),
            index: 11,
          }}
        />
        <Project
          project={{
            name: "KoTH_Canada",
            imgFull: require("../../../images/projects/canada/hero.jpg"),
            index: 12,
          }}
        />
        <Project
          project={{
            name: "PL_Brook",
            extraCSS: "col-span-2 lg:col-span-1",
            imgFull: require("../../../images/projects/brook/hammer.jpg"),
            index: 13,
          }}
        />
      </div>
      <style jsx>{`
        div {
          grid-auto-rows: 1fr;
        }
      `}</style>
    </>
  );
}
function LinkWrapper({
  project,
  prefetch,
  children,
}: {
  prefetch: boolean;
  children: React.ReactNode;
  project: Project;
}) {
  const ariaLabel = project.name;

  if (project.link) {
    return (
      <Link
        href={project.link || "#"}
        prefetch={prefetch ? undefined : false}
        passHref
        legacyBehavior
      >
        <a
          target={project.linkIsExternal ? "_blank" : ""}
          rel="noopener"
          aria-label={ariaLabel}
          className={classNames("relative", project.extraCSS)}
        >
          {children}
        </a>
      </Link>
    );
  } else {
    return (
      <div
        aria-label={ariaLabel}
        tabIndex={0}
        className={classNames("relative", project.extraCSS)}
      >
        {children}
      </div>
    );
  }
}

type Project = {
  name: string;
  description?: string | React.ReactNode;
  imageDescription?: string;
  imgFull: string;
  extraCSS?: CSSProp;
  contain?: boolean;
  link?: string;
  linkIsExternal?: boolean;
  index: number;
};
function Project({ project }: { project: Project }) {
  const shouldPrefetch = !(project.linkIsExternal ?? false);

  const accentColor = generateColorFromIndex(project.index);

  return (
    <>
      <LinkWrapper prefetch={shouldPrefetch} project={project}>
        <div>
          <div>
            <div className="absolute top-0 w-full h-full bg-white">
              <Image
                src={project.imgFull}
                layout="fill"
                objectFit={project.contain ? "contain" : "cover"}
                placeholder="blur"
                alt={project.imageDescription ?? ""}
              />
            </div>
          </div>
          <div
            className={classNames(
              "absolute top-0 w-full h-full px-6 py-5 text-lg text-gray-200 transition-opacity duration-200 opacity-0",
              project.description ? "hover:opacity-100" : "hover:opacity-25"
            )}
            style={{
              backgroundColor: "rgba(40,40,40,0.80)",
            }}
          >
            {project.description ?? ""}
          </div>
          <div
            className={classNames(
              "absolute bottom-0 w-full px-4 py-1 text-lg text-center truncate pointer-events-none md:text-2xl"
            )}
            style={{
              borderBottom: `solid 4px ${accentColor}`,
              backgroundColor: "rgba(255,255,255,0.95)",
            }}
          >
            <div className="flex items-center justify-center space-x-3">
              <div className="truncate">{project.name}</div>
              <div>
                {project.linkIsExternal && (
                  <div className="w-4 h-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LinkWrapper>
    </>
  );
}
