import { cx } from "class-variance-authority";
import { format } from "date-fns";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRef } from "react";
import { CSSProp } from "styled-components";
import { FontSegoe } from "./fonts/segoe";

export function Article({
  article,
  borderColor,
}: {
  article: {
    title: string;
    pubDate: Date;
    link: string;
    thumbnail: string;
  };
  borderColor: CSSProp;
}) {
  const renderDate = format(article.pubDate, "do LLL ''yy");
  const linkRef = useRef<HTMLAnchorElement>(null);

  return <>
    <div
      onClick={(e) => {
        if (e.target !== linkRef.current) {
          linkRef.current?.click();
        }
      }}
      className={
        "flex flex-col justify-end w-full h-full overflow-hidden rounded-lg shadow-lg cursor-pointer"
      }
    >
      <div className="relative flex-grow h-32">
        <div className="absolute top-0 w-full h-full">
          <Image
            src={article.thumbnail}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            alt={""}
          />
        </div>
      </div>
      <div className={cx("border-b", borderColor)}>
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255)",
          }}
          className={cx(
            "w-full px-4 py-2 text-lg border-t border-gray-300 rounded-b hover:bg-gray-200"
          )}
        >
          <Link href={article.link} passHref ref={linkRef}>
            {article.title}
          </Link>
          <br />
          <FontSegoe>
            <small className="text-gray-600 uppercase">{renderDate}</small>
          </FontSegoe>
        </div>
      </div>
    </div>
  </>;
}
