import { CSSProp } from "styled-components";
import { cx as classNames } from "class-variance-authority";

export function RightTriangle({ extraCSS }: { extraCSS: CSSProp }) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 1 1"
      preserveAspectRatio="none"
      className={classNames("h-full w-full", extraCSS)}
    >
      <polygon points="0 1, 1 1, 1 0" />
    </svg>
  );
}

export function LeftTriangle({ extraCSS }: { extraCSS: CSSProp }) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 1 1"
      preserveAspectRatio="none"
      className={classNames("h-full w-full", extraCSS)}
    >
      <polygon points="0 0, 0 1, 1 1" />
    </svg>
  );
}
