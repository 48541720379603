import Head from "next/head";

export function FontCabin({ children }) {
  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/_next/static/media/cabin-v14-latin-regular.9af0f246ea403317304cd96e16ae19e0.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Head>
      <span className={"font-cabin"}>{children}</span>
    </>
  );
}
